<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-row>
        <b-col cols="12">
          <b-card>
            <b-form-group
              label="Marka"
              label-for="brand"
            >
              <validation-provider
                #default="{ errors }"
                name="Marka"
                rules="required"
              >
                <v-select
                  id="brand"
                  v-model="saleGoal.id_com_brand"
                  :options="brands"
                  label="name"
                  :reduce="brand => brand.id"
                  placeholder="Marka"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Hedef"
              label-for="goal"
            >
              <validation-provider
                #default="{ errors }"
                name="Hedef"
                rules="required"
              >
                <b-form-input
                  id="goal"
                  v-model="saleGoal.goal"
                  placeholder="Hedef"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Günlük Bağlantı"
              label-for="daily_sale"
            >
              <validation-provider
                #default="{ errors }"
                name="Günlük Bağlantı"
                rules="required"
              >
                <b-form-input
                  id="daily_sale"
                  v-model="saleGoal.daily_sale"
                  placeholder="Günlük Bağlantı"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Toplam Bağlantı"
              label-for="connection"
            >
              <validation-provider
                #default="{ errors }"
                name="Toplam Bağlantı"
                rules="required"
              >
                <b-form-input
                  id="connection"
                  v-model="saleGoal.connection"
                  placeholder="Toplam Bağlantı"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Tahsis Bağlantı"
              label-for="tahsis_baglanti"
            >
              <validation-provider
                #default="{ errors }"
                name="Tahsis Bağlantı"
                rules="required"
              >
                <b-form-input
                  id="tahsis_baglanti"
                  v-model="saleGoal.tahsis_baglanti"
                  placeholder="Tahsis Bağlantı"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Ay"
              label-for="month"
            >
              <validation-provider
                #default="{ errors }"
                name="Ay"
                rules="required"
              >
                <v-select
                  id="month"
                  v-model="saleGoal.month"
                  :options="months"
                  label="title"
                  :reduce="months => months.id"
                  placeholder="Ay"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Yıl"
              label-for="year"
            >
              <validation-provider
                #default="{ errors }"
                name="Yıl"
                rules="required"
              >
                <b-form-input
                  id="year"
                  v-model="saleGoal.year"
                  placeholder="Yıl"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-card>
          <b-button
            variant="danger"
            @click="saveData"
          >
            Kaydet
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BFormInput, BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Edit',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BFormGroup,
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      locale: 'tr',
      months: [
        {
          id: 1,
          title: 'Ocak',
        },
        {
          id: 2,
          title: 'Şubat',
        },
        {
          id: 3,
          title: 'Mart',
        },
        {
          id: 4,
          title: 'Nisan',
        },
        {
          id: 5,
          title: 'Mayıs',
        },
        {
          id: 6,
          title: 'Haziran',
        },
        {
          id: 7,
          title: 'Temmuz',
        },
        {
          id: 8,
          title: 'Ağustos',
        },
        {
          id: 9,
          title: 'Eylül',
        },
        {
          id: 10,
          title: 'Ekim',
        },
        {
          id: 11,
          title: 'Kasım',
        },
        {
          id: 12,
          title: 'Aralık',
        },
      ],
    }
  },
  computed: {
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    saleGoal() {
      return this.$store.getters['saleGoals/getSaleGoal']
    },
    saveStatus() {
      return this.$store.getters['saleGoals/getSaleGoalSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: 'Kayıt işleminiz başarılı!',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: 'Kayıt işleminiz yapılamadı.! Lütfen tekrar deneyiniz.',
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    localize(this.locale)
    this.getBrands()
    this.getSaleGoal()
  },
  methods: {
    getSaleGoal() {
      this.$store.dispatch('saleGoals/saleGoalView', this.$route.params.id)
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList')
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.$store.dispatch('saleGoals/saleGoalSave', this.saleGoal)
        }
      })
    },
  },
}
</script>
